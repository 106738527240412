<template>
<div class="quanju">
<div class="logimg">
  <img class="log" src="./log.png" />
</div>
<div class="textname">
  <p style="font-size: 12px; text-align: center; margin-top: -1vh; font-weight: 900; color: #69646a;">游戏出海，让世界0距离！</p><br>
    
  <p style="font-size: 16px; text-align: center; margin-top: -2vh; font-weight: 900; color: #69646a;">官方加速器（VPN）/iME Direct下载渠道<br></p>

  <!-- <div class="zhongbu">
    <el-button type="success" round>点击下载加速器</el-button>
  </div> -->
  <div class="zhongbu" style="margin-top: 1vh; text-align: center; font-size: 12px; color: #6964ff;">
    <!-- <el-button type="success" round @click="tcjc">点击查看加速器配置教程</el-button> -->
     <div>
      <el-image
      style="width: 100px; height: 100px;"
      :src="url"
      :zoom-rate="1.2"
      :max-scale="7"
      :min-scale="0.2"
      :preview-src-list="srcList"
      :initial-index="4"
      fit="cover"
    />
    <p style="text-align: center;">安卓会员配置教程</p>
     </div>
    <div>
      <el-image
          style="width: 100px; height: 100px; margin-left: 15vw;"
          :src="urla"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="srcLista"
          :initial-index="4"
          fit="cover"
        />
        <p style="text-align: center; margin-left: 15vw;">安卓更换节点教程（提速）</p>
    </div>
  </div>
  <div class="zhongbu" style="margin-top: 1vh; text-align: center; font-size: 12px; color: #6964ff;">
    <!-- <el-button type="success" round @click="tcjc">点击查看加速器配置教程</el-button> -->
     <div>
      <el-image
      style="width: 100px; height: 100px;"
      :src="urlb"
      :zoom-rate="1.2"
      :max-scale="7"
      :min-scale="0.2"
      :preview-src-list="srcListb"
      :initial-index="4"
      fit="cover"
    />
    <p style="text-align: center;">苹果会员配置教程</p>
     </div>
  </div>
    <!-- <div>
      <p style="text-align: center; font-size: 12px; color: #6964ff;">点击图片查看加速器配置教程</p>
    </div> -->
    <el-divider style="margin: 10px 0 10px 0" />
  <div class="zhongbu" style="margin-top: 1vh;">
    <el-button style="color: #696488;" type="success" round @click="downloadApka">点击下载加速器</el-button>
    <el-button style="color: #696488;" type="success" round @click="downloadApkb">点击下载BonChat(新萝卜密聊)</el-button>
  </div>
  <div class="zhongbu" style="margin-top: 1vh;">
    <el-button style="color: #696488;" type="success" round @click="downloadApkc">点击下载欧易交易所</el-button>

  </div>
  <el-divider style="margin: 10px 0 10px 0;" />
  <div class="zhongbu" style="margin-top: 1vh;" v-for="(item, index) in data" :key="index">
    <!-- <p class="gxsj">更新时间: {{item.time}} </p> -->
    <el-button type="success" round @click="copyText(item.URL)">{{ item.name }}</el-button>
  </div>
  <!-- <p style="font-size: 14px; text-align: center;"><i style="color: red;">配置码1、配置码2作用相同，任意复制一个即可</i><br><br>配置码每日更新<br>如现有配置码到期不能使用的可以从新配置新的配置码<br></p> -->
   <p class="zysq">
    注意事项：<br>
    1：网络加速器（VPN）仅为出海会员提供使用，切勿分享给他人使用，一经发现，取消免费使用资格。<br>
    2：加速器仅在助力和iME聊天时使用，闲时请及时关闭，以免造成网速受限及手机高电耗。<br>
    3：严禁使用加速器刷抖音、看电影、下载文件，如后台发现违规使用，则封禁个人使用权限。<br>
    4：公司免费为会员提供，仅限出海业务使用。<br>
  </p>
</div>
</div>
<!-- <div v-if="imgshow">
  <img src="jc.jpg" alt="">
</div> -->
</template>

<script setup>
import { ref, onMounted } from 'vue';
import imgdata from './jc.jpg';
import imgdatas from './jc1.png';
import imgdatb from './pgjc.jpg';
import { ElMessage } from 'element-plus'

const data = ref([]);

// const apkUrla = '/clash.apk'; 
// const apkUrlb = '/iMe.apk'; 

const downloadApka = () => {
  // APK 文件的相对路径（public 文件夹下）
  const apkUrla = '/clash.apk'; 

  // 创建一个隐藏的 a 标签用于下载
  const a = document.createElement('a');
  a.href = apkUrla;
  a.download = 'clash.apk'; // 指定下载文件的名称
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click(); // 自动触发点击事件
  document.body.removeChild(a); // 移除 a 元素
};

const downloadApkb = () => {
  // // APK 文件的相对路径（public 文件夹下）
  // const apkUrlb = 'iMe.apk'; 

  // // 创建一个隐藏的 a 标签用于下载
  // const a = document.createElement('a');
  // a.href = apkUrlb;
  // a.download = 'iMe.apk'; // 指定下载文件的名称
  // a.style.display = 'none';
  // document.body.appendChild(a);
  // a.click(); // 自动触发点击事件
  // document.body.removeChild(a); // 移除 a 元素
  window.open("https://www.ciphchat.com/", "_blank");
};

const downloadApkc = () => {
  // APK 文件的相对路径（public 文件夹下）
  const apkUrlc = '/okx.apk'; 

  // 创建一个隐藏的 a 标签用于下载
  const a = document.createElement('a');
  a.href = apkUrlc;
  a.download = 'okx.apk'; // 指定下载文件的名称
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click(); // 自动触发点击事件
  document.body.removeChild(a); // 移除 a 元素
};

const copyText = async (text) => {
  try {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

    setTimeout(() => {
      ElMessage({
        message: '复制配置码成功！',
        type: 'success',
        plain: true,
      })
    }, 500);
  } catch (err) {
    console.error('复制失败:', err);
  }
};

onMounted(async () => {
  const response = await fetch('/pzurl.json');
  data.value = await response.json();
  // console.log(data);
});

// const imgshow = ref(false);
// const tcjc = () => {
//   imgshow.value = true
// }
const url = imgdata;
const srcList = [
imgdata
];
const urla = imgdatas;
const srcLista = [
imgdatas
];
const urlb = imgdatb;
const srcListb = [
imgdatb
];
</script>

<style scoped>
.quanju{
  width: 96vw;
  /* height: 98vh; */
  /* background-color: aqua; */
  background: linear-gradient(to bottom, rgb(209, 225, 228), rgb(112, 147, 201), rgb(56, 48, 148));
}
.logimg{
  width: 50vw;
}
.log{
  width: 50vw;
  margin-left: 23vw;
  color: #69646a;
}
.textname{
  width: 90vw;
  margin-left: 3vw;
  font-size: 9px;
  color: aliceblue;
}
.zhongbu{
  width: 90vw;
  /* margin-left: 3vw; */
  display: flex;
  justify-content: center;
}
.gxsj{
  font-size: 10px;
  border: 1px solid rgb(104, 197, 61);
  padding: 6px;
  margin-top: 0.3vh;
  margin-right: 5vw;
  border-radius: 20px;
  background-color: rgb(104, 197, 61);
}
.zysq{
  padding: 0 0 10vh 0; 
  font-size: 16px; 
  color: rgba(229, 245, 11, 0.8);
}
</style>
